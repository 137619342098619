






























































import { NetworkStatChat } from "@/includes/types/networks";

import { EntityTypes } from "piramis-base-components/src/components/SelectEntityWizard/includes/types";

import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import ChatLicense from "@/includes/logic/ChatLicense";

@Component({})
export default class NetworkChatCard extends Vue {
  @Prop() chat!: NetworkStatChat

  @Prop() readonly!: boolean

  @Emit()
  removeChatFromNetwork() {
    return this.chat.chat_id
  }

  chatLicense = new ChatLicense(this.chat)

  gotoChat() {
    this.$router.push({
      name: 'Community_dashboard',
      params: { [EntityTypes.CHAT_ID]: this.chat.chat_id.toString() }
    })
  }
}
