

































































import { NetworkStat, NetworkStatChat } from "@/includes/types/networks";
import NetworkChatsList from "@/components/networks/NetworkChatsList.vue";
import { InputSetups } from "@/mixins/input-setups";

import Accordion from "piramis-base-components/src/components/Accordion/Accordion.vue";
import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";
import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";

import { Component, Prop, Emit, Mixins } from 'vue-property-decorator';

@Component({
  components: {
    Accordion,
    NetworkChatsList,
    SelectInput
  }
})
export default class NetworkChats extends Mixins(InputSetups) {
  @Prop() chats!: NetworkStat['chats']

  @Prop() onAddChats!: (chatsToAdd: Array<number>) => Promise<Array<number>>

  @Prop() readonly!: boolean

  @Emit()
  removeChatFromNetwork(chatId: NetworkStatChat['chat_id']) {
    return chatId
  }

  isAddChatsModalOpen = false

  confirmLoading = false

  chatsToAdd: Array<NetworkStatChat['chat_id']> = []

  async chatsSelectOptions(): Promise<Array<SelectOptionData>> {
    if (!this.$store.state.chatState.chat) {
      await this.$store.dispatch('requestChatsInfo')
    }

    return this.$store.getters.chatSelectOptions.filter((o: SelectOptionData) => !this.chats.find(c => c.chat_id === o.value))
  }

  get otherChatsLength() {
    return this.sortedChats.slice(3).length
  }

  get sortedChats() {
    return this.chats.slice().sort((a, b) => b.member_count - a.member_count)
  }

  addChats() {
    this.confirmLoading = true

    this.onAddChats(this.chatsToAdd)
      .then(res => {
        if (res) {
          this.isAddChatsModalOpen = false
        }
      })
      .finally(() => {
        this.confirmLoading = false
      })
  }
}
