








































enum CurrentChart {
  Users,
  Messages,
}

import { NetworkStat } from "@/includes/types/networks";

import { fromToTicks } from "piramis-base-components/src/shared/utils/fromToTicks";
import { seriesByMetricInList } from "piramis-base-components/src/shared/utils/seriesByMetricInList";
import { ApexChartLocales, ApexChartSeries } from "piramis-base-components/src/shared/types/ApexChartSeries.types";
import getRandomColor from 'piramis-js-utils/lib/getRandomColor'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue';

import { ApexOptions } from "apexcharts";
import moment from "moment/moment";
import { groupBy, maxBy } from "lodash";
import VueApexCharts from "vue-apexcharts";
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  data() {
    return {
      CurrentChart
    }
  },
  components: {
    VueApexCharts,
    ConfigField
  }
})
export default class NetworkSummaryByGroup extends Vue {
  @Prop() statistics!: NetworkStat['summary_statistics_by_group']

  @Prop() chats!: NetworkStat['chats']

  current: CurrentChart = CurrentChart.Users

  usersChart: ApexChartSeries = [ ]

  messagesChart: ApexChartSeries = [ ]

  colors: Array<string> = []

  get currentSeries() {
    if (this.current === CurrentChart.Users) {
      return this.usersChart
    }

    if (this.current === CurrentChart.Messages) {
      return this.messagesChart
    }
  }

  get chartOptions(): ApexOptions {
    return {
      dataLabels: {
        enabled: false
      },
      chart: {
        defaultLocale: this.$i18n.locale,
        locales: ApexChartLocales,
        animations: {
          enabled: false
        },
        type: 'bar',
        stacked: true,
        toolbar: {
          tools: {
            zoom: true,
            download: false,
          },
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      colors: this.colors,
      xaxis: {
        categories: this.timeTicks().timeTicks,
        type: "datetime",
      },
      legend: {
        show: true
      },
    }
  }

  timeTicks() {
    const startOfMonth = moment().startOf('month')

    const from = moment(startOfMonth.clone().add('-1', 'year'), 'YYYY-MM-DD').format('YYYY-MM-DD')
    const momentTo = moment(startOfMonth, 'YYYY-MM-DD').format('YYYY-MM-DD')

    return fromToTicks(from, momentTo, { unit: 'month', amount: 1 })
  }

  created() {
    const groupedSummary = groupBy(this.statistics, 'chat_id')

    const metricsPromises = Object.entries(groupedSummary)
      .reduce((acc: Record<string, Array<Promise<ApexChartSeries>>> , [ chatId, statistics ]) => {
        const chatInfo = this.chats.find(c => c.chat_id === +chatId)

        const maxUsers = maxBy(statistics, 'users_count')
        const maxMessages = maxBy(statistics, 'message_count')

        if (maxUsers && maxUsers.users_count > 0 || maxMessages && maxMessages.message_count > 0) {
          this.colors.push(getRandomColor())
        }

        acc.users.push(
          seriesByMetricInList(this.$i18n, statistics, [ 'users_count' ], this.timeTicks())
            .calcSeries([ { name: chatInfo?.group_title ?? chatId } ])
        )

        acc.messages.push(
          seriesByMetricInList(this.$i18n, statistics, [ 'message_count' ], this.timeTicks())
            .calcSeries([ { name: chatInfo?.group_title ?? chatId } ])
        )

        return acc
      }, {
        users: [],
        messages: []
      })

    Promise.all(metricsPromises.users)
      .then(res => {
        this.usersChart.push(...res.flat())
      })

    Promise.all(metricsPromises.messages)
      .then(res => {
        this.messagesChart.push(...res.flat())
      })

  }
}
