

























import NetworkChatsListItem from "@/components/networks/NetworkChatsListItem.vue";
import { NetworkStat, NetworkStatChat } from "@/includes/types/networks";

import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { ApexChartSeries } from "piramis-base-components/src/shared/types/ApexChartSeries.types";

@Component({
  components: {
    NetworkChatsListItem
  }
})
export default class NetworkChatsList extends Vue {
  @Prop() chats!: NetworkStat['chats']

  @Prop() readonly!: boolean

  @Emit()
  removeChatFromNetwork(chatId: NetworkStatChat['chat_id']) {
    return chatId
  }
}
