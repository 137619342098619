var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"network-chats"},[_c('a-modal',{staticClass:"modal-overflow-visible",attrs:{"title":_vm.$t('network_chats_add_chat_title'),"confirm-loading":_vm.confirmLoading,"destroy-on-close":"","ok-text":_vm.$t('add_button'),"after-close":function () { return _vm.chatsToAdd = []; }},on:{"ok":_vm.addChats},model:{value:(_vm.isAddChatsModalOpen),callback:function ($$v) {_vm.isAddChatsModalOpen=$$v},expression:"isAddChatsModalOpen"}},[_c('select-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': this,
          'key': 'chatsToAdd',
          'prefix': 'networks_',
          'options': _vm.chatsSelectOptions,
          'clearable': false,
          'multiple': true,
        }
      }}})],1),_c('a-page-header',{staticClass:"pt-0 px-0",attrs:{"title":_vm.$t('network_dashboard_section_chats')}},[(!_vm.readonly)?_c('a-button',{attrs:{"slot":"extra","icon":"plus","type":"primary"},on:{"click":function($event){_vm.isAddChatsModalOpen = true}},slot:"extra"},[_vm._v(" "+_vm._s(_vm.$t('network_chats_add_chat_title'))+" ")]):_vm._e()],1),_c('network-chats-list',{attrs:{"chats":_vm.sortedChats.slice(0, 3),"readonly":_vm.readonly},on:{"remove-chat-from-network":_vm.removeChatFromNetwork}}),(_vm.sortedChats.length > 3)?_c('accordion',{staticClass:"mt-5",attrs:{"type":"slim","open-title":_vm.$tc('network_dashboard_accordion_show_more', _vm.otherChatsLength, [ _vm.otherChatsLength ]),"close-title":_vm.$tc('network_dashboard_accordion_show_more', _vm.otherChatsLength, [ _vm.otherChatsLength ])}},[_c('network-chats-list',{attrs:{"chats":_vm.sortedChats.slice(3),"readonly":_vm.readonly},on:{"remove-chat-from-network":_vm.removeChatFromNetwork}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }