





































import { NetworkStat } from "@/includes/types/networks";
import DashboardTopActiveUsers from "@/components/CommunityDashboard/DashboardTopActiveUsers.vue";
import DashboardYearStat from "@/components/CommunityDashboard/DashboardYearStat.vue";
import AdminUsersStatistics from "@/components/CommunityDashboard/AdminUsersStatistics.vue";

import { Vue, Component, Prop } from 'vue-property-decorator';
import NetworkSummaryByGroup from "@/components/networks/NetworkSummaryByGroup.vue";

@Component({
  components: {
    NetworkSummaryByGroup,
    AdminUsersStatistics,
    DashboardYearStat,
    DashboardTopActiveUsers
  }
})
export default class statistics extends Vue {
  @Prop() statistics!: NetworkStat
}
